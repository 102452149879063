import find from 'lodash.find';

export {
  getUnitById,
  propertyNameWithUnit,
  resolvePictureUrl,
};
function getUnitById(property, unitId) {
  return find(property.units, { id: +unitId });
}

function propertyNameWithUnit(property, unit) {
  if (unit.name) {
    return `${property.short_address} - ${unit.name}`;
  }
  return property.short_address;
}

function resolvePictureUrl(property) {
  if (!property) return null;

  if (property.picture_url) {
    return property.picture_url;
  }

  if (property.units) {
    const unitWithImages = property.units.find((unit) => unit.images && unit.images.length > 0);
    return unitWithImages ? unitWithImages.images[0] : null;
  }

  return null;
}
